import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/seo'

import LanguageSwitch from '../components/LanguageSwitch'

import { Link } from 'gatsby'

const NotFoundPage = props => (
  <Layout
    renderLogo={<Link to={`/en`} className="dbk-header--logo" />}
    renderLanguageSwitch={<LanguageSwitch currentLang={'en'} />}
  >
    <SEO title="404: Not found" />
    <div className="col-12 col-lg-9 pb-4">
      <h1 className="mb-4 mt-4">NOT FOUND</h1>
      <p>You visited a page that doesn&#39;t exist...</p>
      <p>
        Return to <Link to="/en">homepage</Link>
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
